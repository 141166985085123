<template>
    <div v-if="$auth.hasPermissions('read:bank_accounts')" class="list-group">
        <div v-for="bank in banks"
             :key="bank.id"
             class="d-flex flex-column mb-0 p-0 list-group-item list-group-item-action"
             :class="{ selected: optionIsSelected(bank.id) }">
            <div @click="selectOption(bank.id)" class="d-flex p-3">
                <span class="d-flex my-auto flex-column text-nowrap">
                    {{ bank.account_last_4.match(/.{1,4}/g).join(' ') }}
                    <span class="ml-auto text-muted ">
                        {{ bank.ownership_type }}
                        {{ bank.account_type }}
                    </span>
                </span>
                <span class="ml-3 my-auto">
                    {{ bank.bank_name }}
                    {{ bank.account_holder_name }}
                </span>
                <span class="ml-3 my-auto text-muted ">
                    {{ bank.notes }}
                </span>
                <div v-if="optionIsSelected(bank.id) && $auth.hasSomePermission('update:bank_accounts', 'delete:bank_accounts')"
                     class="ml-auto my-auto pl-2">
                    <div class="dropdown" @click.stop="toggleDropdown()">
                        <button class="btn btn-link dropdown-toggle text-white"
                                type="button"
                                ref="selectedActionDropdown"
                                data-toggle="dropdown">
                            <span class="fas fa-ellipsis-h fa-fw" />
                        </button>
                        <div class="dropdown-menu">
                            <!-- Using .stop event modifier because this is already inside of an @click -->
                            <button v-if="$auth.hasPermissions('update:bank_accounts')"
                                    @click.stop="selectAction(EDIT)"
                                    class="dropdown-item"
                                    type="button">
                                Edit
                            </button>
                            <button v-if="$auth.hasPermissions('delete:bank_accounts')"
                                    @click.stop="selectAction(DELETE)"
                                    class="dropdown-item"
                                    type="button">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <transition name="slide" v-if="$auth.hasPermissions('update:bank_accounts')">
                <div v-if="actionIsSelected(bank.id, EDIT)" class="bg-white text-700" style="cursor: default">
                    <form @submit.prevent="editBankAccount(bank.id)" class="mx-4 my-3" id="edit-action-form">
                        <div class="row">
                            <div class="col-sm-4 mb-3 d-flex flex-column">
                                <label for="cc-email">Email</label>
                                <input v-model="bankEmail"
                                       class="form-control"
                                       id="cc-email"
                                       type="email">
                            </div>
                            <div class="col-sm mb-3 d-flex flex-column">
                                <label for="cc-notes">Bank Notes</label>
                                <input v-model="bankNotes"
                                       class="form-control"
                                       id="cc-notes"
                                       type="text"
                                       maxlength="140">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex">
                                <button @click="selectOption(bank.id)"
                                        class="btn btn-sm btn-falcon-default"
                                        type="button">
                                    Cancel
                                </button>
                                <button class="btn btn-sm btn-falcon-primary ml-2"
                                        type="submit"
                                        form="edit-action-form"
                                        :disabled="disableActionButtons || processing">
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </transition>

            <transition name="slide" v-if="$auth.hasPermissions('delete:bank_accounts')">
                <div v-if="actionIsSelected(bank.id, DELETE)" class="bg-white text-700" style="cursor: default">
                    <div class="mx-4 my-3 d-flex flex-column">
                        Delete this bank account? You will not be able to recover it.
                        <div class="mt-2">
                            <button @click="selectOption(bank.id)"
                                    class="btn btn-sm btn-falcon-default"
                                    type="button">
                                Cancel
                            </button>
                            <button class="btn btn-sm btn-falcon-danger ml-2"
                                    type="button"
                                    :disabled="disableActionButtons || processing"
                                    @click="deleteBankAccount(bank.id)">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div v-if="$auth.hasPermissions('create:bank_accounts')"
             class="d-flex flex-column mb-0 p-0 list-group-item list-group-item-action"
             :class="{ selected: optionIsSelected(CREATE) }">
            <div @click="selectOption(CREATE)" class="d-flex p-3">
                New Bank Account
            </div>
            <transition name="slide">
                <div v-if="optionIsSelected(CREATE)" class="bg-white text-700 rounded" style="cursor: default">
                    <BraintreeBankAccountFields :customer-id="customerId"
                                                :processing="processing"
                                                @loadingSuccess="$emit('loadingSuccess')"
                                                @loadingError="(message) => $emit('loadingError', message)"
                                                @submissionSuccess="appendBankAccount"
                                                @submissionError="(message) => $emit('submissionError', message)"
                                                class="mx-4 my-3" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { deleteBankAccount, getBankAccountList, updateBankAccount } from '@/services/BankAccountsService';
    import BraintreeBankAccountFields from '@/components/BraintreeBankAccountFields';

    const CREATE = 'CREATE';
    const EDIT = 'EDIT';
    const DELETE = 'DELETE';

    export default {
        name: 'CustomerBankAccounts',
        components: {
            BraintreeBankAccountFields,
        },
        props: {
            customerId: { type: String, required: true },
            customerPrefillEmail: { type: String, default: '' },
            processing: { type: Boolean },
        },
        data() {
            return {
                CREATE,
                DELETE,
                EDIT,
                bankEmail: '',
                bankNotes: '',
                banks: [],
                disableActionButtons: false,
                selectedAction: null, // null, EDIT, or DELETE
                selectedOption: null, // null, bank.id, or CREATE
            };
        },
        watch: {
            /**
             * @emits update:selectedOption Includes BankAccount.id or null
             */
            selectedOption: function() {
                if (this.selectedOption === this.CREATE) {
                    this.$emit('update:selectedOption', null);
                } else {
                    this.$emit('update:selectedOption', this.selectedOption);
                }
            },
        },
        /**
         * Init banks by getting them from the API.
         * @emits loadingSuccess
         * @emits loadingError Includes error message
         * @returns {Promise<void>}
         */
        async mounted() {
            try {
                const { data: banks } = await getBankAccountList({ 'customer': this.customerId });
                this.banks = banks;
                this.$emit('loadingSuccess');
            } catch (error) {
                this.$emit('loadingError', error.message);
            }
        },
        methods: {
            /**
             * Toggle the selectedActionDropdown.
             */
            toggleDropdown() {
                $(this.$refs.selectedActionDropdown).dropdown('toggle');
            },
            /**
             * Set selectedOption and reset selectedAction.
             * @param {string} option BankAccount.id
             */
            selectOption(option) {
                if (!this.processing) {
                    this.selectedAction = null;
                    if (this.selectedOption === option) {
                        this.selectedOption = null;
                    } else {
                        this.selectedOption = option;
                    }
                }
            },
            /**
             * Check selectedOption is the same as param.
             * @param {string} option BankAccount.id
             */
            optionIsSelected(option) {
                return this.selectedOption === option;
            },
            /**
             * Set selectedAction if param is valid. If param is EDIT, prefills bankEmail and bankNotes.
             * @param {string} action EDIT, or DELETE constant
             */
            selectAction(action) {
                // eslint-disable-next-line vue/max-len
                if (!this.processing && this.selectedOption !== null && (action === this.EDIT || action === this.DELETE)) {
                    if (action === this.EDIT) {
                        const bank = this.banks.find(bank => bank.id === this.selectedOption);
                        this.bankEmail = bank.email;
                        this.bankNotes = bank.notes;
                    }
                    this.selectedAction = action;
                    this.toggleDropdown();
                }
            },
            /**
             * Check selectedOption is the same as option param and selectedAction is the same as action param.
             * @param {string} option BankAccount.id
             * @param {string} action EDIT, or DELETE constant
             */
            actionIsSelected(option, action) {
                return this.selectedOption === option && this.selectedAction === action;
            },
            /**
             * Append a BankAccount object to banks.
             * @param bank A BankAccount object
             * @emits submissionSuccess
             */
            appendBankAccount(bank) {
                this.banks.push(bank);
                this.selectOption(bank.id);
                this.$emit('submissionSuccess');
            },
            /**
             * Edit an existing bank account's email or notes fields. Use bankEmail and bankNotes for the new values.
             * @param {string} option BankAccount.id
             * @emits submissionSuccess
             * @emits submissionError Includes error message
             * @returns {Promise<void>}
             */
            async editBankAccount(option) {
                this.disableActionButtons = true;
                try {
                    const { data: data } = await updateBankAccount(option, {
                        'email': this.bankEmail,
                        'notes': this.bankNotes,
                    });
                    this.selectOption(option);
                    this.banks.splice(this.banks.findIndex(bank => bank.id === option), 1, data);
                    this.$emit('submissionSuccess');
                } catch {
                    this.$emit('submissionError', 'Encountered an error while editing a bank account.');
                } finally {
                    this.disableActionButtons = false;
                }
            },
            /**
             * Delete an existing bank account.
             * @param {string} option BankAccount.id
             * @returns {Promise<void>}
             */
            async deleteBankAccount(option) {
                this.disableActionButtons = true;
                try {
                    await deleteBankAccount(option);
                    this.selectOption(null);
                    this.banks.splice(this.banks.findIndex(bank => bank.id === option), 1);
                    this.$emit('submissionSuccess');
                } catch {
                    this.$emit('submissionError', 'Encountered an error while deleting a bank account.');
                } finally {
                    this.disableActionButtons = false;
                }
            },
        },
    };
</script>

<style scoped>
    .list-group-item {
        cursor: pointer;
        user-select: none;
    }

    .dropdown-toggle:after {
        content: none
    }

    input {
        /* Same as the Braintree hosted fields font */
        font-family: "Arial", "sans-serif";
    }

    .selected {
        background-color: #2c7be5;
        border-color: #2c7be5;
        color: #FFF;
    }

    /* Override Bootstrap's text-muted color when selected */
    .selected .text-muted {
        color: inherit !important;
    }

    .slide-enter-active {
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
    }

    .slide-leave-active {
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slide-enter-to, .slide-leave {
        max-height: 400px;
        overflow: hidden;
    }

    .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }
</style>
