<template>
    <form @submit.prevent="createCreditCard" v-if="$auth.hasPermissions('create:credit_cards')">
        <div class="row mb-1">
            <div class="col">
                <i class="fab fa-cc-visa fa-2x mr-2" />
                <i class="fab fa-cc-mastercard fa-2x mr-2" />
                <i class="fab fa-cc-amex fa-2x mr-2" />
                <i class="fab fa-cc-discover fa-2x mr-2" />
                <i class="fab fa-cc-jcb fa-2x mr-2" />
                <i class="fab fa-cc-diners-club fa-2x mr-2" />
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 mb-3 d-flex flex-column">
                <label for="cc-number">Card Number</label>
                <div class="form-control" id="cc-number" />
            </div>
            <div class="col-sm-3 mb-3 d-flex flex-column">
                <label for="cc-expiration">Expiry Date</label>
                <div class="form-control" id="cc-expiration" />
            </div>
            <div class="col-sm-3 mb-3 d-flex flex-column">
                <label for="cc-cvv" class="d-flex">
                    Security Code
                    <i class="fas fa-fw fa-question-circle my-auto ml-auto cursor-pointer"
                       v-tooltip
                       data-html="true"
                       title="For Visa, MasterCard, Discover, JCB, and Diners Club the 3 digits on the <i>back</i>
                        of your card. <br><br> For American Express, the 4 digits on the <i>front</i> of your card." />
                </label>
                <div class="form-control" id="cc-cvv" />
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 mb-3 d-flex flex-column">
                <label for="cc-cardholder-name">Cardholder Name</label>
                <div class="form-control" id="cc-cardholder-name" />
            </div>
            <div class="col-sm-4 mb-3 d-flex flex-column">
                <label for="cc-email" class="d-flex">
                    Email
                    <i class="fas fa-fw fa-question-circle my-auto ml-auto cursor-pointer"
                       v-tooltip
                       data-html="true"
                       title="Enter an email address that will be associated this card." />
                </label>
                <input v-model="ccEmail"
                       class="form-control"
                       id="cc-email"
                       type="email">
            </div>
            <div class="col-sm-4 mb-3 d-flex flex-column">
                <label for="cc-postal-code" class="d-flex">
                    ZIP/Postal Code
                    <i class="fas fa-fw fa-question-circle my-auto ml-auto cursor-pointer"
                       v-tooltip
                       data-html="true"
                       title="Enter the ZIP/postal code for the credit card's billing address." />
                </label>
                <div class="input-group">
                    <input v-model="ccPostalCode"
                           class="form-control"
                           id="cc-postal-code"
                           type="text"
                           minlength="4"
                           maxlength="10"
                           required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-3 d-flex flex-column">
                <label for="cc-notes">Card Notes</label>
                <input v-model="ccNotes"
                       class="form-control"
                       id="cc-notes"
                       type="text"
                       maxlength="140">
            </div>
        </div>
        <div class="d-flex">
            <button class="btn btn-falcon-primary btn-sm ml-auto"
                    type="submit"
                    :disabled="buttonState !== READY || processing">
                <i v-if="buttonState === READY" class="fas fa-fw fa-lock mr-1" />
                <i v-else-if="buttonState === LOADING" class="fas fa-fw fa-circle-notch fa-spin mr-1" />
                <i v-else-if="buttonState === COMPLETE" class="fas fa-fw fa-check mr-1" />
                Add
            </button>
        </div>
    </form>
</template>

<script>
    import * as Sentry from '@sentry/vue';
    import braintree from 'braintree-web';
    import client from 'braintree-web/client';
    import { createCreditCard } from '@/services/CreditCardsService';
    import { getBraintreeClientToken } from '@/services/BraintreeService';

    const READY = 'READY';
    const LOADING = 'LOADING';
    const COMPLETE = 'COMPLETE';

    export default {
        name: 'BraintreeHostedFields',
        props: {
            customerId: { type: String, required: true },
            customerPrefillName: { type: String, default: '' },
            customerPrefillEmail: { type: String, default: '' },
            processing: { type: Boolean, default: false },
        },
        data() {
            return {
                READY,
                LOADING,
                COMPLETE,
                buttonState: READY, // READY, LOADING, or COMPLETE
                ccEmail: this.customerPrefillEmail,
                ccNotes: '',
                ccPostalCode: '',
                hostedFieldsInstance: null,
            };
        },
        /**
         * Init Braintree hosted fields.
         * @emits loadingSuccess
         * @emits loadingError Includes error message
         */
        async mounted() {
            try {
                const { data: token } = await getBraintreeClientToken(this.customerId);
                const clientInstance = await client.create({ authorization: token });
                this.hostedFieldsInstance = await braintree.hostedFields.create({
                    client: clientInstance,
                    styles: {
                        input: {
                            'font-size': '1rem',
                            color: '#344050',
                        },
                    },
                    fields: {
                        number: {
                            selector: '#cc-number',
                        },
                        expirationDate: {
                            selector: '#cc-expiration',
                            placeholder: 'MM / YY',
                        },
                        cvv: {
                            selector: '#cc-cvv',
                        },
                        cardholderName: {
                            selector: '#cc-cardholder-name',
                            prefill: this.customerPrefillName,
                        },
                    },
                });
                this.$emit('loadingSuccess');
            } catch (err) {
                Sentry.captureException(err);
                this.$emit('loadingError', 'Encountered an error while loading the New Card form.');
            }
        },
        methods: {
            /**
             * Create a CreditCard using Braintree hosted fields and input data from the user.
             * @emits submissionSuccess Includes new CreditCard as arg
             * @emits submissionError Includes error message
             * @returns {Promise<void>}
             */
            async createCreditCard() {
                this.buttonState = this.LOADING;
                try {
                    const payload = await this.hostedFieldsInstance.tokenize();
                    const response = await createCreditCard({
                        'customer_id': this.customerId,
                        'payment_method_nonce': payload.nonce,
                        'postal_code': this.ccPostalCode,
                        'email': this.ccEmail,
                        'notes': this.ccNotes,
                    });
                    this.buttonState = this.COMPLETE;
                    setTimeout(() => {
                        this.$emit('submissionSuccess', response.data);
                        this.buttonState = this.READY;
                    }, 300);
                } catch (err) {
                    Sentry.captureException(err);
                    this.$emit('submissionError', 'Encountered an error while adding a card. Check form input.');
                    this.buttonState = this.READY;
                }
            },
        },
    };
</script>

<style scoped>
    input {
        /* Same as the Braintree hosted fields font */
        font-family: "Arial", "sans-serif";
    }
</style>

