<template>
    <form @submit.prevent="createBankAccount" v-if="$auth.hasPermissions('create:bank_accounts')">
        <div class="row">
            <div class="col-12 col-md-6 form-group">
                <label for="routing-number">Routing Number</label>
                <input v-model.trim="bank.routingNumber"
                       id="routing-number"
                       class="form-control"
                       type="text"
                       minlength="9"
                       maxlength="9"
                       required>
            </div>
            <div class="col-12 col-md-6 form-group">
                <label for="account-number">Account Number</label>
                <input v-model.trim="bank.accountNumber"
                       id="account-number"
                       class="form-control"
                       type="text"
                       minlength="4"
                       maxlength="17"
                       required>
            </div>
            <div class="col-12 col-md-6 form-group">
                <label for="ownership-type">Ownership Type</label>
                <select v-model="bank.ownershipType" id="ownership-type" class="custom-select" required>
                    <option value="" />
                    <option value="business">Business</option>
                    <option value="personal">Personal</option>
                </select>
            </div>
            <div class="col-12 col-md-6 form-group">
                <label for="account-type">Account Type</label>
                <select v-model="bank.accountType" id="account-type" class="custom-select" required>
                    <option value="" />
                    <option value="CHECKING">Checking</option>
                    <option value="SAVINGS">Savings</option>
                </select>
            </div>
        </div>
        <transition name="slide" mode="out-in">
            <div v-if="bank.ownershipType === 'business'" class="row">
                <div class="col-12 form-group">
                    <label for="organization">Business Name</label>
                    <input v-model.trim="bank.businessName"
                           id="organization"
                           class="form-control"
                           type="text"
                           maxlength="50"
                           required>
                </div>
            </div>
            <div v-else-if="bank.ownershipType === 'personal'" class="row">
                <div class="col-md-6 form-group">
                    <label for="given-name">First Name</label>
                    <input v-model.trim="bank.firstName"
                           id="given-name"
                           class="form-control"
                           type="text"
                           maxlength="25"
                           required>
                </div>
                <div class="col-md-6 form-group">
                    <label for="family-name">Last Name</label>
                    <input v-model.trim="bank.lastName"
                           id="family-name"
                           class="form-control"
                           type="text"
                           maxlength="25"
                           required>
                </div>
            </div>
        </transition>
        <h2 class="fs-0 my-3">Billing Information</h2>
        <div class="row mb-3">
            <div class="col-12 form-group">
                <label for="address-line1" class="d-flex align-items-center">
                    Address
                </label>
                <input v-model.trim="bank.billingAddress.streetAddress"
                       id="address-line1"
                       class="form-control form-control-sm"
                       type="text"
                       maxlength="50"
                       required>
            </div>
            <div class="col-12 form-group">
                <label for="address-line2" class="d-flex align-items-center">
                    <span class="mr-1">Apartment, Suite, etc.</span>
                    <small>(Optional)</small>
                </label>
                <input v-model.trim="bank.billingAddress.extendedAddress"
                       id="address-line2"
                       class="form-control form-control-sm"
                       type="text"
                       maxlength="50">
            </div>
            <div class="col-12 col-md-6 form-group">
                <label for="address-level2" class="d-flex align-items-center">
                    City
                </label>
                <input v-model.trim="bank.billingAddress.locality"
                       id="address-level2"
                       class="form-control form-control-sm"
                       type="text"
                       maxlength="50"
                       required>
            </div>
            <div class="col-12 col-md-6 form-group">
                <label for="address-level1" class="d-flex align-items-center">
                    State
                </label>
                <select v-model="bank.billingAddress.region"
                        class="custom-select custom-select-sm"
                        id="address-level1"
                        required>
                    <option v-for="state in states" :key="state.code" :value="state.code">
                        {{ state.name }}
                    </option>
                </select>
            </div>
            <div class="col-12 col-md-6 form-group">
                <label for="postal-code" class="d-flex align-items-center">
                    Postal Code
                </label>
                <input v-model.trim="bank.billingAddress.postalCode"
                       id="postal-code"
                       class="form-control form-control-sm"
                       type="text"
                       minlength="4"
                       maxlength="9"
                       required>
            </div>
            <div class="col-12 col-md-6 form-group">
                <label for="country" class="d-flex align-items-center">
                    Country
                    <i class="fas fa-fw fa-question-circle ml-auto cursor-pointer"
                       v-tooltip
                       data-html="true"
                       title="Only US bank accounts are supported at this time." />
                </label>
                <select class="custom-select custom-select-sm" id="country" disabled>
                    <option>United States</option>
                </select>
            </div>
            <div class="col-12 form-group">
                <label for="email" class="d-flex align-items-center">
                    <span class="mr-1">Email</span>
                    <small class="mr-auto">(Optional)</small>
                    <i class="fas fa-fw fa-question-circle"
                       v-tooltip
                       data-html="true"
                       title="We will send receipts to this email address." />
                </label>
                <input v-model.trim="bank.email"
                       id="email"
                       class="form-control form-control-sm"
                       type="text"
                       maxlength="50">
            </div>
            <div class="col-12 form-group">
                <label for="bank-notes" class="d-flex align-items-center">
                    <span class="mr-1">Notes</span>
                    <small>(Optional)</small>
                </label>
                <textarea v-model.trim="bank.notes"
                          id="bank-notes"
                          class="form-control"
                          style="min-height: 2rem;"
                          rows="2"
                          maxlength="100" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <span class="fs--1 font-weight-semi-bold">{{ mandateText }}</span>
            </div>
        </div>
        <button class="btn btn-falcon-primary btn-sm ml-auto"
                type="submit"
                :disabled="buttonState !== READY || processing">
            <i v-if="buttonState === READY" class="fas fa-fw fa-lock mr-1" />
            <i v-else-if="buttonState === LOADING" class="fas fa-fw fa-circle-notch fa-spin mr-1" />
            <i v-else-if="buttonState === COMPLETE" class="fas fa-fw fa-check mr-1" />
            Add
        </button>
    </form>
</template>

<script>
    import * as Sentry from '@sentry/vue';
    import client from 'braintree-web/client';
    import { createBankAccount } from '@/services/BankAccountsService';
    import { getBraintreeClientToken } from '@/services/BraintreeService';
    import usBankAccount from 'braintree-web/us-bank-account';
    import { usStates } from '@/data';

    const READY = 'READY';
    const LOADING = 'LOADING';
    const COMPLETE = 'COMPLETE';

    export default {
        name: 'BraintreeBankAccountFields',
        props: {
            customerId: { type: String, required: true },
            processing: { type: Boolean },
        },
        data() {
            return {
                READY,
                LOADING,
                COMPLETE,
                buttonState: READY, // READY, LOADING, or COMPLETE
                usBankAccountInstance: null,
                bank: {
                    accountNumber: '',
                    routingNumber: '',
                    accountType: '',
                    ownershipType: 'business',
                    billingAddress: {
                        streetAddress: '',
                        extendedAddress: '',
                        locality: '',
                        region: '',
                        postalCode: '',
                    },
                    firstName: '',
                    lastName: '',
                    businessName: '',
                    email: '',
                    notes: '',
                },
                states: usStates.slice().sort((a, b) => a.name.localeCompare(b.name)),
            };
        },
        computed: {
            mandateText() {
                return 'By clicking Add, I authorize Braintree, a service of PayPal, on behalf of Apyx Medical (i) ' +
                    'to verify my bank account information using bank information and consumer reports and (ii) to ' +
                    'debit my bank account.';
            },
        },
        /**
         * Init UsBankAccountInstance.
         * @emits loadingSuccess
         * @emits loadingError Includes error message
         */
        async mounted() {
            try {
                const { data: token } = await getBraintreeClientToken(this.customerId);
                const clientInstance = await client.create({ authorization: token });
                this.usBankAccountInstance = await usBankAccount.create({ client: clientInstance });
                this.$emit('loadingSuccess');
            } catch (err) {
                Sentry.captureException(err);
                this.$emit('loadingError', 'Encountered an error while loading the New Bank Account form.');
            }
        },
        methods: {
            /**
             * Create a BankAccount using Braintree hosted fields and input data from the user.
             * @emits submissionSuccess Includes new BankAccount as arg
             * @emits submissionError Includes error message
             * @returns {Promise<void>}
             */
            async createBankAccount() {
                this.buttonState = this.LOADING;
                try {
                    const { nonce } = await this.usBankAccountInstance.tokenize({
                        bankDetails: this.bank,
                        mandateText: this.mandateText,
                    });
                    const { data: bank } = await createBankAccount({
                        customer_id: this.customerId,
                        payment_method_nonce: nonce,
                        email: this.bank.email,
                        notes: this.bank.notes,
                    });

                    this.buttonState = this.COMPLETE;
                    setTimeout(() => {
                        this.$emit('submissionSuccess', bank);
                        this.buttonState = this.READY;
                    }, 300);
                } catch (err) {
                    Sentry.captureException(err);
                    this.$emit('submissionError',
                               'Encountered an error while adding a Bank Account. Check form input.');
                    this.buttonState = this.READY;
                }
            },
        },
    };
</script>

<style scoped>
    input {
        /* Same as the Braintree hosted fields font */
        font-family: "Arial", "sans-serif";
    }
</style>

